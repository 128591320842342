//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }

  tr:nth-child(odd) td {
    background-color: #f7f7f7; // Color for odd rows
  }

  tr:nth-child(even) td {
    background-color: white; // Color for even rows
  }
}

.table-dark>:not(:last-child)>:last-child>* {
  border-bottom-color: $table-dark-border-color;
}


.table-bordered {
  border: $table-border-width solid $table-border-color;
}

.table-nowrap {

  th,
  td {
    white-space: nowrap;
  }
}

.table>:not(:first-child) {
  border-top: 0;
}