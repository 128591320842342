.dashboardLink {
  margin: 0 20px 20px 0;
  border: 1px solid #dbe2e6;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  transition: 0.2s ease all;

  &:hover {
    background-color: #ebf7ff;
    border-color: #ebf7ff;
  }

  >div {
    display: flex;
    gap: 10px;
  }
}

.total-items-badge {
  display: inline-block;
  padding: 11px;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px;
  background-color: #fff6b8;
  color: #000000;
  box-shadow: 0 0 0 1px #d1d6db;

  span {
    font-weight: 700;
  }
}

.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.thin-cell {
  padding: 0.5rem 0.75rem;
  max-width: 120px;
}

.data {
  padding: 1rem;
  margin-top: 80px;

  @media (max-width: 768px) {
    padding-left: 0.25rem;
  }
}

.data-title {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin: 0 0 0.15rem 0.5rem;
    font-size: 1.25rem;
  }
}

.data-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  width: 100%;
}

.data-box {
  flex: 1;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 1rem;
  margin: 0.5rem;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
  }

  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
  }
}

.data-key {
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  margin-right: 10px;
}

.data-value {
  margin-bottom: 0.25rem;
}

.data-subkey {
  font-weight: bold;
}

.data-row {
  display: flex;
  justify-content: space-between;
  flex: 1;

  a {
    color: #007bff;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: orange;
    }
  }
}

.data-row-items {
  display: flex;
  flex-direction: column;
}

.data-subgroup {
  display: flex;
  flex-direction: column;

  strong {
    margin-right: 0.5rem;
  }
}

.data-image {
  width: 75px;
  height: 75px;
  margin-bottom: 1rem;
  border-radius: 50%;
}

.realities {
  margin: 1rem 0;
}

.realities-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.reality-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #f9f9f9;

  >div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

.column {
  flex: 1;
  padding: 0.5rem 0.2rem;
  gap: 1rem;
}

.reality-title {
  font-size: 1.2rem;
  margin-bottom: 0.25rem;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.reality-info {
  margin-bottom: 0.5rem;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
}

.reality-info strong {
  font-weight: bold;
}

.row-hover {
  td {
    line-height: 14px;
    padding-bottom: 2px;
    padding-top: 2px;
    vertical-align: middle;
    transition: 0.2s ease all;
  }

  &:hover {
    td {
      box-shadow: none;
      background-color: #fff6b8 !important;
    }
  }
}

.page-content {
  margin-top: 80px;
  padding: 20px;

  &.stats {
    margin-top: 100px;
  }
}

.stats-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  // on mobile they should be fullwidth
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.voices {
  margin-top: 20px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.pink {
  background-color: #fff2f3;
  color: #f3059d;
}

.blue {
  background-color: #e8f8ff;
  color: #004085;
}

.white-block {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;

  h2 {
    display: inline-block;
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  .refreshData {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .localButton {
    position: absolute;
    top: 10px;
    right: 115px;

    &.active {
      background-color: #0056b3;
      color: white;
    }
  }
}

.addLanguage {
  position: absolute;
  top: 80px;
  left: 20;
  z-index: 1000;

  &.active {
    background-color: #0056b3;
    color: white;
  }
}

.stats-block {
  position: relative;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  /* Add box shadow */

  &.row {
    flex-direction: row;

    >div {
      flex: 1;
    }
  }
}

.stats-block-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.03;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.stats-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px dashed lightgrey;
  padding: 15px 0 10px;

  // last item should not have margin bottom or paddingBottom
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.bold {
  font-weight: bold;
}

.stats-label {
  // font-weight: bold;
  text-transform: uppercase;
  color: black;
  font-size: 1em;
  margin-bottom: 0;
}

.countries {
  margin-top: 20px;

  .stats-item.header {
    padding: 0 0 10px;
  }

  .stats-table {
    display: flex;
    width: 100%;
  }

  .stats-column {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* Aligns content to the right */
    padding: 0 10px;
    /* Adds space between columns */
  }

  .stats-title {
    font-weight: bold;
    margin-bottom: 5px;
    text-align: right;
  }

  .stats-value {
    text-align: right;
    font-size: 1.2em;
    margin-bottom: 0;
  }

  .stats-label {
    width: 140px;
  }

  .stats-ratio {
    font-weight: bold;
  }

  // i=on phones hide > span
  @media (max-width: 768px) {
    .stats-label {
      width: auto;

      >span {
        display: none;
      }
    }

    .stats-ratio {
      margin-left: 1px;
    }

    font-size: 10px;

    .header {
      font-size: 10px;
    }
  }
}

.green {
  color: #3aa10a;
}

.stats-value {
  flex: 1;
  text-align: right;
  font-size: 1.2em;
  margin-bottom: 0;

  &.bold {
    font-weight: bold;
  }
}

.stats-value-green {
  color: #3aa10a;
  font-weight: bold;

  .stats-ratio {
    color: #3aa10a;
    font-weight: bold;
  }
}

.stats-ratio {
  margin-left: 5px;
  font-weight: 500;
  color: #2ba7e2;
  // font-weight: bold;
}

.highlighted {
  font-weight: bold;
  padding: 10px 5px;

  &.grey {
    background-color: #f9f9f9;

    .stats-label,
    >p,
    >p>span {
      color: #6c757d;
    }
  }

  &.green {
    background-color: #eefff2;

    .stats-label,
    >p,
    >p>span {
      color: #009622;
    }
  }

  &.orange {
    background-color: #ffefd7;

    .stats-label,
    >p,
    >p>span {
      color: #da7500;
    }
  }

  &.blue {
    background-color: #e8f8ff;

    .stats-label,
    >p,
    >p>span {
      color: #004085;
    }
  }

  &.pink {
    background-color: #fff2f3;

    .stats-label,
    >p,
    >p>span {
      color: #f3059d;
    }
  }
}

.refresh {
  position: absolute;
  top: 77px;
  right: 20px;
}

.loading {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1000;
}

.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Content
.card {
  margin-bottom: 5px;
}

.card-header {
  background-color: antiquewhite !important;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  transition: 0.2s ease all;

  &:hover {
    background-color: #f0f0f0 !important;
  }

  &.active {
    background-color: #fff6b8 !important;
  }
}

.card-body {
  span {
    font-weight: bold;
  }
}

.scrollable-tabpane {
  max-height: 650px;
  overflow-y: auto;
}

.bold {
  font-weight: bold;
}

.flex-row {
  display: flex;
  gap: 10px;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column !important;
  }
}

.flex-2 {
  flex: 2;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

// Buttons
.white-button {
  background-color: white;
  color: #0056b3;
  border: none;
  padding: 6px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease all;
  border: 1px solid #0056b3;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #0056b3;
    color: white;
  }
}

.button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 4px 11px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease all;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #004080;
  }
}

.validation {
  display: inline-flex;
  align-items: center;
  padding: 5px 15px 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  position: relative;

  .icon {
    margin-right: 8px;
    border-radius: 50%;
    padding: 3px;
    font-size: 12px;
  }

  &.valid {
    background-color: #d4edda;
    color: #155724;

    .icon {
      color: #155724;
      background-color: #c3e6cb;
    }
  }

  &.invalid {
    background-color: #ffcccc;
    color: #990000;

    .icon {
      color: #990000;
      background-color: #ff9999;
    }
  }

  &.disabled {
    opacity: 0.55;
    background-color: #ccc;

    .icon {
      color: #000000;
      background-color: #d1d6db;
    }
  }
}

// Add language
.add-language {
  display: flex !important;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background-color: white;
  border-radius: 10px;

  h3 {
    font-size: 14px;
    font-weight: normal;
  }

  .new-language-title {
    padding-right: 20px;

    h2 {
      font-size: 1.35em;
      margin-bottom: 20px;
    }
  }

  .new-language-block {
    flex: 1;
    flex-direction: row;
    border-left: 1px dashed lightgrey;
    border-right: 1px dashed lightgrey;
    padding: 0 20px;

    &:last-child {
      margin-right: 0;
      border-right: none;
    }

    &:first-child {
      border-left: none;
    }
  }

  .form-group {
    // display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: langFlagExist;

    input {
      flex: 1;
      margin-right: 10px;
    }

    button {
      width: 100%;
      margin-top: 5px !important;
    }
  }

  .new-language-config-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px dashed lightgrey;
    padding: 10px 5px;
    position: relative;

    // remove padding top from first item and padding bottom from last item
    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  .new-language-actions {
    flex: 3;
    flex-direction: row;
    display: flex;
    width: 100%;

    &.disabled {
      opacity: 0.55;
      pointer-events: none;
    }
  }

  .new-language-config-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    border-bottom: 1px dashed lightgrey;
    padding: 10px 5px;
    width: 100%;

    >div {
      flex: 1;
    }

    h4 {
      margin-bottom: 0;
    }
  }

  // on mobile devices flex shold be column
  @media (max-width: 768px) {
    flex-direction: column;

    .new-language-actions {
      width: 100%;
      padding: 0;
      flex-direction: column;

      >.new-language-block {
        padding-bottom: 20px;
      }
    }

    .new-language-title,
    .new-language-block {
      width: 100%;
      padding: 0;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .new-language-action {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .btn {
      width: 100%;
    }

    .status-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 5px;
      border: 1px dotted lightgrey;
      width: 100%;

      h4 {
        margin-right: 10px;
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }
}

.addVoice {
  margin-top: 20px;
  border-top: 1px dashed lightgrey;
  padding: 10px 0;
  padding-top: 20px;

  .errorMessage {
    color: red;
    text-align: center;
    margin-top: 1rem;
  }
}

.addButton {
  width: 100%;
}

/* Add sentence block */
.button-row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.content-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  >div {
    flex: 1;
  }
}

.current-sentences,
.add-sentence-form {
  flex: 1 1 calc(50% - 10px);
  box-sizing: border-box;
}

.add-sentence-form {
  display: none;
}

.addSentence.active+.add-sentence-form {
  display: block;
}

.addNotificationForm {
  flex: 1;
}

@media (max-width: 768px) {

  .current-sentences,
  .add-sentence-form {
    flex: 1 1 100%;
  }

  .content-row {
    flex-direction: column;
  }
}

.add-sentence-block {
  .btn-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 5px;
  }

  .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    .d-flex {
      flex: 1;
      justify-content: space-between;
    }

    label {
      flex: 1;
      display: flex;
      align-items: center;
    }

    input {
      flex: 3;
      margin-right: 10px;
    }

    button {
      flex: 1;
      margin-left: 10px;
    }
  }

  button {
    max-width: 300px;
    padding: 3px 5px;
    font-size: 12px;
  }
}

/* Layout adjustments for desktop and mobile */
@media (min-width: 768px) {
  .white-block {
    display: flex;
    flex-direction: column;

    .tab-content {
      flex: 1;
    }
  }
}

@media (max-width: 768px) {
  .white-block {
    flex-direction: column;

    .nav,
    .tab-content {
      width: 100%;
    }
  }

  .add-language {
    flex-direction: column !important;
  }
}

/* User detail screen */
.user-page-content {
  padding: 20px;
  background-color: #f9f9f9;
  margin-top: 70px;
}

.user-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.user-tokens {
  color: #333;
  font-size: 16px;
}

.token-spend {
  background-color: #efeeca;
  color: #141414;
  border-radius: 20px;
  padding: 5px 10px;
  display: inline-block;
  margin-left: 10px;
  font-weight: bold;
}


.token-amount {
  background-color: #4CAF50;

  color: white;
  border-radius: 20px;

  padding: 5px 10px;

  display: inline-block;
  margin-left: 10px;
}

.user-data-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.user-data-box {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 350px;
}

.user-realities {
  width: 100%;
  background-color: #e3e3e3;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}

.user-realities-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.user-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (min-width: 768px) {
  .user-data-container {
    justify-content: flex-start;
  }

  .user-data-box {
    width: calc(50% - 20px);
  }
}

.inputGroupStyle {
  margin-bottom: 5px;
}

.addButton {
  width: '100%';
  margin-top: 20;
}

.audio-options {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;

  .audio-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed lightgray;
    padding: 10px 0;

    &:first-child {
      margin-top: 1rem;
      border-top: 1px dashed lightgray;
    }

    p {
      display: inline-block;
      margin: 0 1rem 0 auto;

      &:first-child {
        margin-left: 0;
      }

      >strong {
        display: block;
      }
    }

    audio {
      // flex-grow: 1;
      margin: 0 1rem;
    }

    button {
      white-space: nowrap;
    }
  }
}

/* Emails screen */
#emails-page-content {
  margin-top: 70px;
  padding: 20px;

  .emails-content-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .emails-button-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  .white-block {
    height: calc(100vh - 150px);
  }

  .emails-button-group {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-top: auto;
    padding: 0;
    position: absolute;
    bottom: 20px;

    .email-count {
      margin: auto;
      padding: 0 10px;
      font-size: 14px;
      color: #555;
    }
  }

  .emails-current-sentences {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .email-textarea {
    width: 100%;
    height: calc(100% - 60px);
    border-radius: 5px;
    border: 1px solid #d1d6db;
    padding: 10px;
    font-size: 12px;
    resize: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .form-check-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }

  .form-check {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    margin-right: 0;

    &.checked {
      background-color: #d4edda;
      border: 1px solid #c3e6cb;
    }
  }

  .form-check-input {
    margin-right: 10px;
    margin-left: 0;
  }

  .form-check-label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .form-check img {
    margin-left: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .copied-message {
    position: absolute;
    bottom: 20px;
    right: 90px;
    background-color: #d4edda;
    padding: 8px;
    border-radius: 5px;
    color: #155724;
  }

  .no-items-message {
    position: absolute;
    bottom: 20px;
    right: 75px;
    left: 'auto';
    background-color: #f8d7da;
    padding: 8px;
    border-radius: 5px;
    color: #721c24;
  }

  @media (max-width: 768px) {
    .emails-content-row {
      flex-direction: column;
    }

    .emails-button-row {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .email-textarea {
      height: 200px;
    }
  }
}

// Users
.circle {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.circleText {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.user-info-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;

  .third-section {
    flex: 1 1 33%;
    padding: 0 1rem;
    margin-bottom: 1rem;
    /* Unified margin for mobile view */

    h2 {
      margin: 15px 0;
      padding-bottom: 10px;
      border-bottom: 1px solid lightgrey;
    }
  }

  .data-row {
    margin-bottom: 0.5rem;
  }

  .data-key {
    font-weight: bold;
    margin-right: 0.5rem;
  }

  .flag,
  .icon {
    margin-left: 0.5rem;
  }

  .flag {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  .icon,
  .toggle-icon {
    font-size: 14px;
  }

  .toggle-icon {
    margin-left: auto;
    font-size: 22px;
    cursor: pointer;
    transition: transform 0.3s ease;
    line-height: 12px;
  }

  .toggle-icon.open {
    transform: rotate(180deg);
  }

  .reality-item {
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;

    &.current-reality .reality-header {
      background-color: #e0ffe0;
      /* Light green for current reality */
    }
  }

  .reality-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
    cursor: pointer;

    strong {
      flex-grow: 1;
      margin-right: 10px;
    }
  }

  .reality-meta {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 5px;
  }

  .reality-createdBy {
    font-size: 12px;
    color: #666;
    background-color: #ffffff;
    border-radius: 50%;
    padding: 2px 7px 2px 0;
    text-align: center;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    margin: 0 2px;

    >i {
      margin: 0;
    }
  }

  .reality-details {
    padding: 10px;
    background-color: #fff;
  }

  .items-acquired-container {
    margin-top: 1rem;
  }

  .item-acquired {
    background-color: #e0ffe0;
    border: 1px solid #c8e6c9;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 0.5rem;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

    p {
      margin: 0;
      font-size: 14px;
      color: #333;

      strong {
        margin-right: 5px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .third-section {
      flex: 1 100%;
      margin-bottom: 1rem;
    }
  }
}

.left-section,
.right-section,
.third-section {
  flex: 1;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 1rem;
  margin: 0.5rem;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
  }
}

.data-value {
  margin-bottom: 0.25rem;
}

.data-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;

  &:nth-child(odd) {
    background-color: #f9f9f9;
  }

  >p {
    margin: 0;
  }

  .data-key {
    font-weight: bold;
    text-transform: capitalize;
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.flag {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.green-badge {
  background-color: green;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
}

.red-badge {
  background-color: red;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
}

.purple-badge {
  background-color: purple;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  font-weight: bold;
}

#sidebar-menu {
  h4 {
    margin-top: 20px;
    margin-left: 28px;
    margin-bottom: 15px;
    font-size: 14px;
    text-transform: uppercase;
    color: #a4baff;
  }

  .separator {
    border-top: 1px dashed #a6b0cf;
    margin: 1rem;
    opacity: 0.3;
  }
}

/* Reality Images Section */
.reality-images-section {
  margin-top: 2rem;
}

.reality-images-section h3 {
  margin-bottom: 1rem;
  color: #333;
  font-size: 20px;
}

/* Images Gallery */
.images-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-thumbnail {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative;
}

.image-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-thumbnail:hover {
  transform: scale(1.05);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.full-image {
  // max-width: 1000px;
  max-height: 890px;
  margin: 0 auto;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 10px #000;
}

/* Close Button */
.close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  color: #fff;
  background-color: #333;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;
  z-index: 1001;
}

/* Navigation Buttons */
.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  z-index: 1002;
}

.nav-button.left {
  left: -30px;
}

.nav-button.right {
  right: -30px;
}

.nav-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .image-thumbnail {
    width: 80px;
    height: 80px;
  }

  .nav-button.left {
    left: 10px;
  }

  .nav-button.right {
    right: 10px;
  }
}



// Push notifications
#push-notifications-page {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  .flag-icon {
    width: 33px;
    margin-left: 10px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
  }

  .flag-icon-small {
    width: 20px;
    margin-left: 5px;
    box-shadow: 1px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
  }

  .search-panel,
  .notification-editor {
    width: 49%;
    background-color: #ffffff;
    /* Changed to white */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    /* More focused shadow */

    .translation-form {
      border-bottom: 1px solid #e2e2e2;
      border-top: 1px solid #e2e2e2;
      padding-bottom: 20px;
      padding-top: 20px;
    }

    // submit
    .submit-section {
      margin-top: 20px;
      float: right;

      button {
        margin-right: 0;
      }
    }
  }

  .input-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    input {
      width: 49%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }

  button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .blue-button {
    background-color: #007bff;
    color: white;
    margin-right: 10px;
  }

  .green-button {
    background-color: #28a745;
    color: white;
    margin-right: 10px;
  }

  .orange-button {
    background-color: #fd7e14;
    color: white;
    margin-right: 10px;
  }

  button[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .button-group {
    text-align: right;
  }

  .user-info,
  .notification-form,
  .translation-form {
    margin-top: 20px;
  }

  .notification-editor {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    /* More focused shadow */
  }

  h3,
  h4 {
    margin-bottom: 15px;
    color: #333;
    text-transform: uppercase;
    font-size: 16px;
    background-color: aliceblue;
    padding: 5px 10px;
    border-radius: 3px;
  }

  .user-info {
    h4 {
      font-weight: bold;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 5px;
      font-weight: normal;
      padding: 10px;

      &:nth-child(odd) {
        background-color: #ffffff;
      }

      &:nth-child(even) {
        background-color: #f0f8ff;
      }
    }
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  textarea {
    resize: none;
  }

  select {
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    text-transform: uppercase;
  }

  .error-message {
    color: red;
    margin-top: 10px;
  }

  button.green-button {
    background-color: #28a745;
    color: white;
    margin-right: 10px;
  }

  button[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

// Ratings
#ratings-page-content {
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .ratings-list {
    margin-top: 20px;
  }

  .ratings-table {
    display: flex;
    flex-direction: column;
    border-collapse: collapse;
    width: 100%;
  }

  .ratings-header,
  .ratings-row {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .ratings-column {
    width: 25%;
    text-align: left;
  }

  .flag-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

// Copy to clipboard
.copy-container {
  display: flex;
  align-items: center;
  position: relative;
}

.copy-label {
  margin-right: 8px;
  font-weight: bold;
}

.copy-text {
  margin-right: 8px;
}

.copy-button {
  background: #007bff;
  border: none;
  border-radius: 50%;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s;
}

.copy-button:hover {
  background: #0056b3;
}

.copy-icon {
  font-size: 14px;
}

.copy-tooltip {
  position: absolute;
  top: -25px;
  right: 0;
  background: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  opacity: 0.9;
  animation: fadeInOut 2s;
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0;
  }

  10%,
  90% {
    opacity: 0.9;
  }
}

// Feedback.scss
.response-cell,
.status-cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px; // Ensure space for the button on the right
  line-height: 16px;
}

.feedback-button {
  position: absolute;
  top: 2px;
  right: 5px;
  background-color: #709dce;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 4px 7px;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;

  &.edit-button {
    background-color: #709dce;
  }

  &.save-button {
    background-color: #28a745;
    top: 35px;
  }

  &.cancel-button {
    background-color: #ffbfbf;
    color: black;
    margin-left: 5px;
    top: 2px;
  }

  &.loading {
    background-color: #6c757d;
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover:not(.loading) {
    background-color: #0056b3;
  }
}

.spinner {
  margin-left: 10px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.status-select {
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
}

.status-select.loading {
  background-color: #f3f3f3;
  color: #aaa;
}

.response-display {
  display: flex;
  align-items: center;
  padding-right: 40px; // Ensure space for the button on the right
}

.response-edit-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100px;
}

.response-actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.response-textarea {
  width: calc(100% - 20px); // Adjust for padding and button
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
  min-height: inherit;
  line-height: 18px;
}

.response-text {
  flex: 1;
  margin: 0;
  padding-right: 10px;
}

// Firebase button
/* Add this to your stylesheets */
.firebase-link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  background-color: black;
  border-radius: 20px;
  padding: 3px;

  .firebase-logo {
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }

  .firebase-logo:hover {
    transform: scale(1.1);
  }
}